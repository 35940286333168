import React from "react"
import { graphql } from "gatsby"
import Picture from "gatsby-image"
import Layout from "../components/layout"

const HomePage = ({ data }) => {
  return (
    <Layout>
      <h1>
        Caledonian Village{" "}
        <small style={{ color: `#737373` }}> | Dalry, Edinburgh</small>
      </h1>
      <Picture
        className="calevillage-header.jpg"
        fluid={data.file.childImageSharp.fluid}
        alt="calevillage-header.jpg"
      />
      <div
        style={{
          float: `left`,
          maxWidth: `510px`,
          paddingLeft: `20px`,
          paddingRight: `50px`,
          backgroundColor: `#f4fdf4`,
        }}
      >
        <h2>Latest news</h2>
        <blockquote>
          <h3>
            <small style={{ color: `#737373` }}>April 2024</small>
            <br />
            Dalry town centre proposal
          </h3>
          <p>Proposals to make Dalry greener, healthier and more vibrant are being presented for consultation by Edinburgh City Council.</p>
        </blockquote>
        The <a href="/news/">News</a> page contains further updates.
        <h2>Scheduled works</h2>
        <p>
          <b style={{ color: `#737373` }}>Summer 2024</b>
          <br />- Complete monoblock cleaning/sanding
        </p>
      </div>
      <div style={{ float: `left`, maxWidth: `450px`, paddingLeft: `25px` }}>
        <h2>Welcome</h2>
        <p>
          Welcome to our website, which is provided by The Caledonian Village
          Owners’ Association (TCVOA) to assist and inform all residents.
        </p>
        <p>
          The Caledonian Village, Edinburgh was completed around the year 2000
          and stands on the site of the old <a href="https://scotchwhisky.com/whiskypedia/1994/caledonian/">Caledonian Distillery</a>, bounded on
          the north by the railway and Haymarket Station. The large development
          of 301 properties, which is accessed from Dalry Road, comprises 27
          apartment blocks and 11 main door properties, and includes some but
          not all the addresses in Easter Dalry Road, Wynd, Drive, Place, Rigg,
          and Duff Road.
        </p>
        <p>
          The <a href="/info/">Info</a> page contains a map of Caledonian
          Village.
        </p>
        <p>
          This development is managed by Trinity Factoring Services. Please
          refer to the <a href="/factor/">Factor</a> page for more information.
        </p>
        <p>
          TCVOA would ask all residents to please make Caledonian Village a
          welcoming and friendly place to live and help keep our environment
          clean and tidy. Please refer to the <a href="/info/">Info</a> page for
          further details.
        </p>
      </div>
    </Layout>
  )
}

export const query = graphql`
  query {
    file(relativePath: { eq: "images/calevillage-header.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 1000) {
          ...GatsbyImageSharpFluid
        }
      }
    }
  }
`

export default HomePage
